// hooks
import Web3 from "web3";
import { AbiItem } from "web3-utils";
// @ts-ignore
import { ethers } from "ethers";
import Web3Modal from "web3modal";

// utils
import ERC20ABI from "src/utils/abis/ERC20.json";

export const Networks = {
  MainNet: 56,
  Testnet: 97,
  Ethereum: 1,
  Sepolia: 11155111,
};

export const TokenType = {
  1: "ERC20",
  5: "ERC20",
  56: "BEP20",
  97: "BEP20",
  11155111: "ERC20",
};

export const ALL_SUPPORTED_CHAIN_IDS: number[] = [
  Networks.MainNet,
  Networks.Testnet,
  Networks.Ethereum,
  Networks.Sepolia,
];

export const currentNetwork: number =
  parseInt(process.env.REACT_APP_NETWORK_ID || "") || 56;

export const shorter = (str: string) =>
  str?.length > 8 ? str.slice(0, 6) + "..." + str.slice(-4) : str;

export async function getErc20ContractInstance(address: string) {
  let provider = window.ethereum;
  const web3 = new Web3(provider);
  return new web3.eth.Contract(ERC20ABI as AbiItem[], address);
}

export async function getContractInstance(abi: any, address: string) {
  let provider = window.ethereum;
  const web3 = new Web3(provider);
  return new web3.eth.Contract(abi as AbiItem[], address);
}

export const getProvider = async () => {
  const web3Modal = new Web3Modal();
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  return provider;
};

export const getSigner = async () => {
  const web3Modal = new Web3Modal();
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  const signer = provider.getSigner();
  return signer;
};

export const BlockchainNetwork = {
  1: { name: "Ethereum Mainnet", url: "https://etherscan.io/address/" },
  3: { name: "Ropsten Testnet", url: "https://etherscan.io/address/" },
  4: { name: "Rinkeby Testnet", url: "https://etherscan.io/address/" },
  5: { name: "Goerli Testnet", url: "https://etherscan.io/address/" },
  11155111: {
    name: "Seplia Testnet",
    url: "https://sepolia.etherscan.io/address",
  },
  56: { name: "BSC Mainnet", url: "https://bscscan.com/address/" },
  97: { name: "BSC Testnet", url: "https://testnet.bscscan.com/address/" },
};

export const ERC20TokenInfo = {
  BUSD: {
    97: "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee",
    56: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  },
  USDC: {
    97: "0x64544969ed7ebf5f083679233325356ebe738930",
    56: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
  },
  USDT: {
    97: "0x0897202ce1838d0712d357103aae83650a0d426d",
    56: "0x55d398326f99059ff775485246999027b3197955",
  },
};
