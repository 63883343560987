import { HashLink as Link } from "react-router-hash-link";

interface LogoProps {
  background?: boolean;
}

const Logo = ({ background = false }: LogoProps) => {
  return (
    <div className="logo">
      <Link to="/">
        {background ? (
          <>
            <p className="whitesale-logo">White </p>
            <p className="sec whitesale-logo">Sale</p>
          </>
        ) : (
          <p>white creator droplocker - v6</p>
        )}
      </Link>
    </div>
  );
};

export default Logo;
