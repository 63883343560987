import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

// hooks
import useWindowSize from "src/hooks/useWindowSize";
import { useWeb3React } from "@web3-react/core";

// third party
import { Fade } from "react-awesome-reveal";
import toast from "react-hot-toast";

// utils
import { shorter, BlockchainNetwork } from "src/utils";
import { connectorLocalStorageKey, ConnectorNames } from "src/utils/connectors";
import { switchToNetwork } from "src/utils/wallet";
import { SidebarContext } from "src/context/SidebarContext";

// components
import Button from "src/components/Button";
import Container from "src/components/Container";
import Logo from "src/components/Logo";
import useAuth from "src/hooks/useAuth";
import Modal from "src/components/Modal";

interface NavbarProps {
  background?: boolean;
}

const Navbar = ({ background = false }: NavbarProps) => {
  const curPath = useLocation();
  const { chainId, library } = useWeb3React();
  const { toggleSidebar } = useContext(SidebarContext);
  const [path, setPath] = useState<string>("");
  const [menu, setMenu] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const { width } = useWindowSize();
  const refMenu = useRef<any>(null);
  const toggleMenu = () => setMenu(!menu);
  const closeMenu = () => setMenu(false);

  useEffect(() => {
    setPath(curPath.pathname.split("/")[1]);
  }, [curPath]);

  const handleModalClose = () => {
    setModalStatus(false);
  };
  const handleModalOpen = () => {
    if (chainId) setModalStatus(true);
    else toast.error("Connect your wallet first");
  };
  const changeNetwork = async (chainId: number) => {
    await switchToNetwork({ library, chainId });
    setModalStatus(false);
  };

  return (
    <div className={`ld-navbar ${background ? "presale-back" : "token-back"}`}>
      <Container className="">
        {path === "whitesale" && (
          <div className="left-hambuger">
            <img
              onClick={toggleSidebar}
              src="/statistic/assets/landing/icons/menu.svg"
              alt="menu"
              loading="lazy"
            />
          </div>
        )}
        <Logo background={background} />
        <div className="case-network">
          <Button onClick={handleModalOpen}>
            {chainId ? BlockchainNetwork[chainId]?.name : "Choose Network"}
          </Button>
        </div>
        <div className="case-one">
          <ContentSidebar closeMenu={closeMenu} />
        </div>
        <div className="case-two">
          <img
            onClick={toggleMenu}
            src="/statistic/assets/landing/icons/menu.svg"
            alt="menu"
            loading="lazy"
          />
        </div>
        {menu && width < 640 && (
          <Fade duration={300} delay={100} className="fade">
            <div ref={refMenu} className="mobile-menu">
              <Button onClick={handleModalOpen}>
                {chainId ? BlockchainNetwork[chainId]?.name : "Choose Network"}
              </Button>
              <ContentSidebar closeMenu={closeMenu} />
            </div>
          </Fade>
        )}
      </Container>

      <Modal
        isOpen={modalStatus}
        className="choose-network"
        onClose={handleModalClose}
      >
        <div className="nav-box">
          <h1 className="heading">Choose Network</h1>
          <div className="content">
            <div className="network-box" onClick={() => changeNetwork(1)}>
              <img
                src="/statistic/assets/logo/ethereum_logo.png"
                alt="ethereum"
              />
              <div className="chain_name">Ethereum Mainnet</div>
            </div>
            <div className="network-box" onClick={() => changeNetwork(56)}>
              <img
                src="/statistic/assets/logo/binance_logo.png"
                alt="binance"
              />
              <div className="chain_name">BSC Mainnet</div>
            </div>
            <div
              className="network-box"
              onClick={() => changeNetwork(11155111)}
            >
              <img
                src="/statistic/assets/logo/ethereum_logo.png"
                alt="ethereum"
              />
              <div className="chain_name">Sepolia Testnet</div>
            </div>
            <div className="network-box" onClick={() => changeNetwork(97)}>
              <img
                src="/statistic/assets/logo/binance_logo.png"
                alt="binance"
              />
              <div className="chain_name">BSC Testnet</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ContentSidebar = ({ closeMenu }: any) => {
  const { loginWallet } = useAuth();
  const { account } = useWeb3React();

  function connectWallet() {
    loginWallet(ConnectorNames.Injected);
    window.localStorage.setItem(
      connectorLocalStorageKey,
      ConnectorNames.Injected
    );
  }

  return (
    <div className="sidebar">
      <div className="close" onClick={closeMenu}>
        &#10006;
      </div>

      <Button variant="primary" onClick={connectWallet}>
        {account ? shorter(account) : "Connect Wallet"}
      </Button>
    </div>
  );
};

export default Navbar;
